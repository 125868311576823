import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }

  timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        //  return Math.round(elapsed/1000) + ' seconds ago';   
         return 'vừa mới';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' phút trước';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' giờ trước';   
    }

    else if (elapsed < msPerMonth) {
        return '' + Math.round(elapsed/msPerDay) + ' ngày trước';   
    }

    else if (elapsed < msPerYear) {
        return '' + Math.round(elapsed/msPerMonth) + ' tháng trước';   
    }

    else {
        return '' + Math.round(elapsed/msPerYear ) + ' năm trước';   
    }
  }

  makeSlug(alias) {
    let str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|–|-|{|}|\||\\/g," ");
    str = str.replace(/\s+/g," ");
    str = str.trim();
    str = str.replace(/\s/g,"-"); 
    return str;
  }

  getTitle(str) {
      /* Lấy từ ký tự thứ 60 trở đi đến khi gặp dấu xuống dòng */
      const regex = /[^\n\.]+(\n|\.)/us;
      let m;
      if ((m = regex.exec(str)) !== null) {
          return m[0].replace(/\?|\=|\>|\<|\.|\:|\#|\@|\&/g, "").replace(/\_/g, " ").replace(/\n/g, " ");
      } else {
          return ''
      }
  }
  getDescription(message: string) {
    return message.slice(0, 255);
  }
  getKeyword(message: string) {
    return message.slice(0, 255).split(' ').join(',').replace(/,+/g, ',');
  }
}
