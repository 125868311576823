import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from '@/service/helpers.service';
import { NavbarService } from '@/navbar/navbar.service';
import { APIFbDataService } from '@/api/api-fbdata.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SEOTagType } from 'src/app/models/common.model';
import { Meta, Title } from '@angular/platform-browser';
import { ROUTE_PREFIX_LIST } from '@/app.constants';
import { BehaviorSubject, of } from 'rxjs';
import { find, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BoLocService {
  public listHouse: any;
  public fbidList: any;
  public currentPrefix: string;

  public queryPath: any;
  public queryParse: any;
  public title: any;
  public listIsLoading: boolean = false;
  public subscribleRoute: any;

  public currentPage: any;
  public totalPage: any;

  public danhSachDuong: any;
  public danhSachDuongSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public SEOTag: SEOTagType;

  constructor(
    public http: HttpClient,
    public helpersService: HelpersService,
    public navbar: NavbarService,
    public apiFbDataService: APIFbDataService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.SEOTag = {
      h1: '',
      title: '',
      description: '',
      keyword: '',
      breadcrumb: [],
      slug: '',
    };

    this.listHouse = [
      {title: ''}
    ];
  }

  filterAddress(messageType, placeIds, page) {
    if (isNaN(page)) {
      page = 1;
    }
    console.log('filterAddress', messageType, placeIds || '*', Number(page))
    this.listHouse = [];
    for (let i = 0; i < 50; i++) {
      this.listHouse.push({title: ''});
    }

    this.listIsLoading = true;
      this.http.get<any>(`${environment.api.listPosts}/${messageType}/${placeIds[0] || '*'}/${Number(page)}/20`)
      .pipe(mergeMap(data => {
        return of(data.results);
      }))
      // this.apiFbDataService.getList({
      //   type: messageType,
      //   place: placeIds,
      //   page: page
      // })
      .subscribe(listHouseData => {

        // console.log('listHouseData', listHouseData);
        this.listIsLoading = false;

        this.currentPage = listHouseData.pagination && listHouseData.pagination.page || 1;
        this.totalPage = listHouseData.pagination && listHouseData.pagination.total || 1;

        this.fbidList = [];
        listHouseData.list.forEach(element => {
            element.slug = `${element.slug}-${element.id}`;
            element.time = this.helpersService.timeDifference(new Date().getTime(), element.time*1000);
            element.timeupdate = this.helpersService.timeDifference(new Date().getTime(), element.timeupdate*1000);
            element.place = element.area.split(',');
            // console.log(element.place);
            // if (element.place) {
            //     element.place = element.place.split(":").filter(function (el) { return el != ""; });
            // }
            // console.log(element.place);
            
            element.message = element.message.replace(/(?:\r\n|\r|\n)/g, "<br />");
            if (element.fbid && element.fbid !== '') {
              this.fbidList.push(element.fbid);
            }
        });

        this.getAddressList();
        // this.getPlaceList(this.fbidList);
        this.listHouse = listHouseData.list;
        // console.log('this.listHouse', this.listHouse)

    });
  }

  getPlaceList(fbidList) {
    this.apiFbDataService.get_place_list(fbidList).subscribe(res => {
          // console.log(res);
          this.listHouse.forEach(house => {
            res.forEach(place => {
                if (place.fbid === house.fbid) {
                    house.place = place.place.split(",");
                }
            });
          });
    });
  }

  goToCurrentFilter() {
    this.router.navigate([this.SEOTag.slug]);
  }
  pageChange($event) {
    this.currentPage = $event;
    // this.filterAddress(2, [], $event)
    // console.log($event);
    this.router.navigate([this.SEOTag.slug, $event])
  }

  getAddressList() {
    this.danhSachDuong = {
      origin: [],
      keyValue: {}
    };
    this.http.get<any>(`${environment.api.getAddress}`).subscribe(addressList => {
      this.danhSachDuong.origin = addressList.results;
      addressList.results.forEach(element => {
        this.danhSachDuong.keyValue[element.id] = element;
        this.danhSachDuong.keyValue[element.id].slug = this.helpersService.makeSlug(element.tendaydu);
      });
      this.danhSachDuongSub.next(this.danhSachDuong);
      // console.log('this.danhSachDuong', this.danhSachDuong);
    });
  }

  setHTMLTag(path, page) {
    for (let i = 0; i < ROUTE_PREFIX_LIST.length; i++) {
      const queryPrefix = ROUTE_PREFIX_LIST[i].prefix;
      this.currentPrefix = queryPrefix + '-';
      // console.log('currentPrefix-->', this.currentPrefix, path, page, path.substring(0, queryPrefix.length), queryPrefix);
      
      if (path.substring(0, queryPrefix.length) === queryPrefix) {
        const queryTenDuong = path.substring(queryPrefix.length + 1, path.length);
        let queryPath = [];
        if (queryTenDuong) {
          queryPath.push(queryTenDuong);
        }
        // console.log('queryPath', ROUTE_PREFIX_LIST[i], queryPath);

        if (queryPath.length > 0) {
          // Nếu có ghép với đuôi khu vực
          this.danhSachDuongSub.pipe(find(a => a !== null)).subscribe(dsDuong => {
            const timDuong = this.danhSachDuong.origin.find(a => a.slug === queryPath[0]);
            const tenDuong = timDuong && timDuong.tendaydu || '';
            // console.log('this.danhSachDuong', this.danhSachDuong.origin, timDuong);

            this.SEOTag.h1 = `${ROUTE_PREFIX_LIST[i].title} ${tenDuong}`;
            this.SEOTag.title = `${ROUTE_PREFIX_LIST[i].title} ${tenDuong}`;
            this.SEOTag.description = `${ROUTE_PREFIX_LIST[i].description} ${tenDuong}`;
            this.SEOTag.keyword = `${ROUTE_PREFIX_LIST[i].keyword} ${tenDuong}`;
            this.SEOTag.breadcrumb = [
              {text: 'Trang chủ', slug: ['/']},
              {text: ROUTE_PREFIX_LIST[i].title, slug: `/${ROUTE_PREFIX_LIST[i].prefix}`}
            ];
            this.SEOTag.slug = ROUTE_PREFIX_LIST[i].prefix + '-' + queryPath;
            this.setTag(this.SEOTag.h1, this.SEOTag.description, this.SEOTag.keyword);
          });
        } else {
          this.SEOTag.h1 = ROUTE_PREFIX_LIST[i].title;
          this.SEOTag.title = ROUTE_PREFIX_LIST[i].title;
          this.SEOTag.description = ROUTE_PREFIX_LIST[i].description;
          this.SEOTag.keyword = ROUTE_PREFIX_LIST[i].keyword;
          this.SEOTag.breadcrumb = [
            {text: 'Trang chủ', slug: ['/']}
          ];
          this.SEOTag.slug = ROUTE_PREFIX_LIST[i].prefix;
          this.setTag(this.SEOTag.h1, this.SEOTag.description, this.SEOTag.keyword);
        }

        this.filterAddress(ROUTE_PREFIX_LIST[i].id, queryPath, page);
        break;
      }
    }
  }
  setTag(title, description, keywords) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({name: 'description', content: description});
    this.metaService.updateTag({name: 'keywords', content: keywords});
  }

}
