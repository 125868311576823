import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  public serverTime: number = 0;
  public internetTime: number = 0;
  public currentTime: number = 0;
  constructor() {
  }
  getTime(): number {
    return this.serverTime + (new Date().getTime() - this.currentTime + this.internetTime);
  }
}

export function injectString(originstring: string, injectString: string, position: number): string {
  return [originstring.slice(0, position), injectString, originstring.slice(position)].join('');
}

export function range(start: number, end: number): number[] {
  const result: number[] = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function timeAgo(
  elapsed: number
  // current,
  // previous
  ) {

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  // var elapsed = current - previous;

  if (elapsed < msPerMinute) {
      //  return Math.round(elapsed/1000) + ' seconds ago';   
       return 'Just now';   
  }

  else if (elapsed < msPerHour) {
       return Math.round(elapsed/msPerMinute) + ' minutes ago';   
  }

  else if (elapsed < msPerDay ) {
       return Math.round(elapsed/msPerHour ) + ' hours ago';   
  }

  else if (elapsed < msPerMonth) {
      return '' + Math.round(elapsed/msPerDay) + ' days ago';   
  }

  else if (elapsed < msPerYear) {
      return '' + Math.round(elapsed/msPerMonth) + ' months ago';   
  }

  else {
      return '' + Math.round(elapsed/msPerYear ) + ' years ago';   
  }
}