import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from  'src/app/account/account.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NoopInterceptor implements HttpInterceptor {
    constructor(
        private accountService: AccountService
    ) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.accountService.accessToken) {
            const authReq = req.clone({
                headers: req.headers.set('Authorization', `${this.accountService.accessToken}`)
            });
            // send cloned request with header to the next handler.
            return next.handle(authReq);
        }
        const authReq = req.clone({
        });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
}