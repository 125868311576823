import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Title, Meta } from "@angular/platform-browser";
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public menu: any;
  public subMenuDefined: any;
  public subMenu: any;
  public queryObject = new Subject<any>();
  public pageType: string;
  // public routeString = new Subject<string>();
  public onBrowser: any;
  public html: any = {
    h1: '',
    title: '',
    description: '',
  };
  public menuTree: any;
  
  public pathChange = new Subject<any>();

  constructor(
    // @Inject(PLATFORM_ID) private platformId: Object,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
  ) {
    // if (isPlatformBrowser(this.platformId)) {
    //   console.log('onBrowser');
    //   this.onBrowser = true
    // } else {
    //   console.log('onServer');
    //   this.onBrowser = false;
    // }

    this.menu = [
      {name: 'Mua và bán', url: '/can-ban-nha/', sub: 'mua-va-ban', selected: true},
      {name: 'Thuê và cho thuê', url: '/nguoi-can-thue/', sub: 'thue-va-cho-thue', selected: false},
      // {name: 'An sinh', url: '/van-de/', sub: 'thue-va-cho-thue', selected: false},
      {name: 'Nhà đẹp', url: '/nha-dep/', sub: 'thue-va-cho-thue', selected: false},
      {name: 'Tin tức', url: '/tin-tuc-kinh-te-xa-hoi/', sub: 'thue-va-cho-thue', selected: false}
    ];

    this.subMenu = [
      {name: 'Cần bán nhà', url: '/can-ban-nha/', parent: 'mua-va-ban', selected: false},
      {name: 'Cần mua nhà', url: '/nguoi-can-mua-nha/', parent: 'mua-va-ban', selected: false},
      {name: 'Cho thuê', url: '/phong-tro/', parent: 'thue-va-cho-thue', selected: false},
      {name: 'Cần thuê', url: '/nguoi-can-thue/', parent: 'thue-va-cho-thue', selected: false},
      {name: 'Tìm người ở ghép', url: '/tim-nguoi-o-ghep/', parent: 'thue-va-cho-thue', selected: false},
    ];

    this.menuTree = {
      selectedUrl: '/can-ban-nha/',
      subSelectedUrl: '/can-ban-nha/',
      subList: null,
      list: [
      {name: 'Mua & bán', url: '/can-ban-nha/', sub: [
        {name: 'Cần mua nhà', url: '/can-ban-nha/', selected: false},
        {name: 'Cần bán nhà', url: '/nguoi-can-mua-nha/', selected: false},
      ], selected: true},
      {name: 'Thuê & cho thuê', url: '/nguoi-can-thue/', sub: [
        {name: 'Cho thuê', url: '/phong-tro/', selected: false},
        {name: 'Cần thuê', url: '/nguoi-can-thue/', selected: false},
        {name: 'Tìm người ở ghép', url: '/tim-nguoi-o-ghep/', selected: false},
      ], selected: false},
      {name: 'Về nhà', url: '/nha-dep/', sub: [
        {name: 'Nhà đẹp', url: '/nha-dep/', selected: false},
        {name: 'Tin tức', url: '/tin-tuc-kinh-te-xa-hoi/', selected: false}
      ], selected: false},
    ]};
    this.changeRoot('/can-ban-nha/');
 
  }
  changeRoot($event: string): void {
    console.log($event);
    const idx = this.menuTree.list.findIndex(a => a.url === $event);
    this.menuTree.subList = this.menuTree.list[idx].sub;
  }
  changeSub(router: string): void {

  }
  // setSelected(rootUrl: string, childUrl: string) {
  //   const idx = this.menuTree.list.findIndex(a => a.url === rootUrl);
  //   this.menuTree.subList = this.menuTree.list[idx].sub;
  // }

  selectMenu(index) {
    // this.menu.forEach(element => {
    //   element.selected = false;
    // });
    // this.menu[index].selected = true;
    // this.subMenu = this.subMenuDefined[this.menu[index].sub];
  }

  selectSubMenu(index) {
    // this.subMenu.forEach(element => {
    //   element.selected = false;
    // });
    // this.subMenu[index].selected = true;
  }
  setTag(title, description, keywords) {
    // this.titleService.setTitle(title);
    // this.metaService.updateTag({name: 'description', content: description});
    // this.metaService.updateTag({name: 'keywords', content: keywords});
  }

}
