import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent {
  @Input() visible: boolean = false;
  @Input() title: string = '';
  @Input() footer: any = null;
  @Input() okText: string = 'OK';
  @Input() cancelText: string = 'Cancel';
  
  @Output() onCancel = new EventEmitter<void>();
  @Output() onOk = new EventEmitter<void>();

  handleCancel() {
    this.onCancel.emit();
  }

  handleOk() {
    this.onOk.emit();
  }
}
