import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestItemComponent } from './suggest-item.component';
import { RouterModule } from '@angular/router';
import { ImagesLazyloadModule } from 'src/app/components/images-lazyload/images-lazyload.module';

@NgModule({
  declarations: [
    SuggestItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImagesLazyloadModule
  ],
  exports: [
    SuggestItemComponent
  ]
})
export class SuggestItemModule { }
