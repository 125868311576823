import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { LoginModule } from 'src/app/account/login/login.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ModalPopupModule } from '../components/modal-popup/modal-popup.module';
import { ModalDrawerModule } from '../components/modal-drawer/modal-drawer.module';


@NgModule({
  declarations: [
    NavbarComponent
  ],
  imports: [
    CommonModule,
    LoginModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    NzDrawerModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    NzAlertModule,
    ModalPopupModule,
    ModalDrawerModule
  ],
  exports: [
    NavbarComponent
  ]
})
export class NavbarModule { }
