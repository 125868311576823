import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AccountService } from './account/account.service';
import { Platform } from './helpers/platform';
import { BoLocService } from './product-list/danh-sach/bo-loc/bo-loc.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'exhome12';
  public subscribeRoute: any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public boLocService: BoLocService,
    public accountService: AccountService,
    public platform: Platform
  ) {
    this.subscribeRoute = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.subscribeRoute.subscribe(params => {
      console.log('params', params);
      const pathArray = this.router.url.split('/');
      const path = pathArray && pathArray[1] || 'not-found';
      const page = parseInt(pathArray[2]);
      console.log('NavigationEnd', path, page);
      this.boLocService.setHTMLTag(path, page);
    });
    if (this.platform.browser) {
      this.accountService.loadLoginStatus();
    }
    
  }
}
