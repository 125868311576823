<div class="footer">
    <div class="container mx-auto pt-3">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            <div class="px-2 sm:px-1 my-2">
                <h3>Chúng tôi có thể</h3>
                <ul>
                    <li>
                        <a [routerLink]="[]">Tìm kiếm bđs đang bán</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Tìm kiếm bđs đang cho thuê</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Tìm kiếm nội thất cũ đang bán</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Tìm kiếm ô tô cũ đang bán</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Tìm kiếm tin tức Kinh tế - Xã hội</a>
                    </li>
                </ul>
            </div>
            <div class="px-2 sm:px-1 my-2">
                <h3>Về Exhome</h3>
                <ul>
                    <li>
                        <a [routerLink]="[]">Giới thiệu</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Sứ mệnh</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Câu hỏi</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Tuyển dụng</a>
                    </li>
                </ul>
            </div>
            <div class="px-2 sm:px-1 my-2">
                <h3>Liên kết</h3>
                <ul>
                    <li>
                        <a [routerLink]="['chinh-sach-bao-mat.html']">Chính sách bảo mật</a>
                    </li>
                    <li>
                        <a [routerLink]="[]">Điều khoản sử dụng</a>
                    </li>
                </ul>
            </div>
            <div class="px-2 sm:px-1 my-2">
                <h3>Kết nối với chúng tôi</h3>
                <div>Liên hệ: exhome.net@gmail.com</div>
                <div>Điện thoại: 058.299.44.06</div>
                <div>
                    <div>
                        <a href="https://facebook.com/exhome.net" target="_blank">
                            <svg style="width: 26px; height: 26px; color: #0c88ef" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-facebook fa-w-16 fa-2x"><path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" class=""></path></svg>
                        </a>
                    </div>
                    <div>
                        <a [routerLink]="[]">
                            <svg style="width: 26px; height: 26px; color: #c00" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-youtube fa-w-18 fa-2x"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" class=""></path></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex py-3 px-2 sm:px-1 mt-3 copyright">
            <div class="w-full">
                <div>Website thử nghiệm đang trong quá trình hoàn thiện hồ sơ làm thủ tục cấp giấy phép MXH bộ TTTT và giấy phép TMDT với BCT.</div>
                <div>Exhome.net là máy tìm kiếm, không thực tiếp bán/cho thuê, quý khách có nhu cầu vui lòng liên hệ người bán/cho thuê.</div>
                <div>© 2020 - 2022 Exhome.net - Bản quyền thuộc về ExHome.Net</div>
            </div>
        </div>
    </div>
</div>
