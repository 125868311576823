import { CookieService } from '@/service/cookie.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public accessToken: string;
  public userInfo: string;
  public popupLoginOpen: boolean;
  constructor(
    public cookieService: CookieService
  ) { }

  public logout() {
    this.accessToken = null;
    this.userInfo = null;
    this.cookieService.eraseCookie('accessToken');
    this.cookieService.eraseCookie('userInfo');
  }
  public setLoginStatus(data): void {
    this.accessToken = data.accessToken;
    this.userInfo = data.userInfo;
    this.cookieService.setCookie('accessToken', data.accessToken, 1);
    this.cookieService.setCookie('userInfo', JSON.stringify(data.userInfo), 1);
  }

  public loadLoginStatus(): void {
    this.accessToken = this.cookieService.getCookie('accessToken');
    try {
      this.userInfo = JSON.parse(this.cookieService.getCookie('userInfo'));
    } catch (error) {
      this.userInfo = null;
    }
    
  }
}
