import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDrawerComponent } from './modal-drawer.component';



@NgModule({
  declarations: [
    ModalDrawerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ModalDrawerComponent
  ]
})
export class ModalDrawerModule { }
