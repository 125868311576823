<div *ngIf="visible" class="drawer-overlay">
    <div class="drawer"  [ngStyle]="bodyStyle">
      <div class="drawer-header">
        <!-- Kiểm tra nếu title là TemplateRef -->
        <ng-container *ngIf="isTemplateRef; else stringTitle">
          <ng-container *ngTemplateOutlet="title"></ng-container>
        </ng-container>
        <!-- Hiển thị tiêu đề nếu title là string -->
        <ng-template #stringTitle>
          <h2 *ngIf="title && !isTemplateRef">{{ title }}</h2>
        </ng-template>
        <button *ngIf="closable" (click)="handleClose()">X</button>
      </div>
  
      <div class="drawer-body">
        <ng-content></ng-content> <!-- Nội dung của drawer sẽ được truyền từ ngoài vào -->
      </div>
    </div>
  </div>
  