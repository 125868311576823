import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-modal-drawer',
  templateUrl: './modal-drawer.component.html',
  styleUrls: ['./modal-drawer.component.scss']
})
export class ModalDrawerComponent {
  @Input() visible: boolean = false;
  @Input() closable: boolean = true;
  @Input() title: string | TemplateRef<any> | null = null; 
  @Input() placement: 'left' | 'right' | 'top' | 'bottom' = 'left';
  @Input() bodyStyle: { [key: string]: string } = {};

  @Output() onClose = new EventEmitter<void>();

  // Thuộc tính để kiểm tra xem title có phải là TemplateRef không
  get isTemplateRef(): boolean {
    return this.title instanceof TemplateRef;
  }

  handleClose() {
    this.onClose.emit();
  }
}
