import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { retry, catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface GetListRequest {
    type: string;
    place: string;
    placeType?: string;
    page?: number;
    limit?: number;
}
interface GetDetailRequest {
    id: number
}
@Injectable({
  providedIn: 'root'
})
export class APIFbDataService {
    public loading: any = {
        getList: false,
        getDetail: false,
        getAddressList: false,
        threadNhaDep: false
    };
    public error: any = {
        getList: false,
        getDetail: false,
        getAddressList: false,
        threadNhaDep: false
    };
    constructor(private httpClient: HttpClient) { }

    http(name: string, type: string, link: string, request: any) {

    }
    getList(request: GetListRequest): Observable<any> {
        this.loading.getList = true;
        this.error.getList = false;
        return this.httpClient.post(environment.api.getList, request).pipe(
                retry(0),
                switchMap(value => {
                    
                    this.loading.getList = false;
                    return of(value['results'])
                }),
                catchError(err => {
                    
                    this.loading.getList = false;
                    this.error.getList = true;
                    return of('{"hits":{"hits":[]}}'); 
                })
        );
    }

    getDetail(request: GetDetailRequest): Observable<any> {
        this.loading.getDetail = true;
        this.error.getDetail = false;
        return this.httpClient.post(environment.api.getDetail, request).pipe(
                retry(0),
                switchMap(value => {
                    
                    this.loading.getDetail = false;
                    return of(value['results'])
                }),
                catchError(err => {
                    
                    this.loading.getDetail = false;
                    this.error.getDetail = true;
                    return of('{"hits":{"hits":[]}}'); 
                })
        );
    }

    get_place_list(fbidList) {
        this.loading.getAddressList = true;
        this.error.getAddressList = false;
        return this.httpClient.post(environment.api.getPlaceList, {
            fbid: fbidList.join(','),
        }).pipe(
                retry(0),
                switchMap(value => {
                    
                    this.loading.getAddressList = false;
                    return of(value['results'])
                }),
                catchError(err => {
                    
                    this.loading.getAddressList = false;
                    this.error.getAddressList = true;
                    return of('{"hits":{"hits":[]}}'); 
                })
        );
    }

    get_address_list() {
        this.loading.getAddressList = true;
        this.error.getAddressList = false;
        return this.httpClient.get(environment.api.getAddress).pipe(
                retry(0),
                switchMap(value => {
                    
                    this.loading.getAddressList = false;
                    return of(value['results'])
                }),
                catchError(err => {
                    
                    this.loading.getAddressList = false;
                    this.error.getAddressList = true;
                    return of('{"hits":{"hits":[]}}'); 
                })
        );
    }

    get_thread_nha_dep() {
        this.loading.threadNhaDep = true;
        this.error.threadNhaDep = false;
        return this.httpClient.get(environment.api.getThreadNhaDep).pipe(
                retry(0),
                switchMap(value => {
                    
                    this.loading.threadNhaDep = false;
                    return of(value['results'])
                }),
                catchError(err => {
                    
                    this.loading.threadNhaDep = false;
                    this.error.threadNhaDep = true;
                    return of('{"hits":{"hits":[]}}'); 
                })
        );
    }
}
