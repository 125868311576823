import { ExternalScriptService } from  'src/app/helpers/external-script';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Platform } from '@/helpers/platform';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public form: UntypedFormGroup;
  subscribeHighChartCreate: any;
  constructor(
    private fb: UntypedFormBuilder,
    public platform: Platform,
    public externalScriptService: ExternalScriptService
  ) {
    if (this.platform.browser) {
      // this.externalScriptService.load('assets/firebase-app.js');
      // this.externalScriptService.load('assets/firebase-auth.js');
      // this.externalScriptService.listScript['assets/firebase-app.js'].subscribe(app => {
      //   if (app !== null) {
      //     this.externalScriptService.listScript['assets/firebase-auth.js'].subscribe(auth => {
      //       if (auth !== null) {
      //         var firebaseConfig = {
      //           apiKey: "AIzaSyBy_P3AOkcGJ9x7c-FTNl5e4JdEkmcsufQ",
      //           authDomain: "exhome-fac4d.firebaseapp.com",
      //           projectId: "exhome-fac4d",
      //           storageBucket: "exhome-fac4d.appspot.com",
      //           messagingSenderId: "983846796031",
      //           appId: "1:983846796031:web:a52a7a8e91fe39ef696aca",
      //           measurementId: "G-M8FWQ1P8G6"
      //         };
      //         // Initialize Firebase
      //         (window as any).firebase.initializeApp(firebaseConfig);
      //       }
      //     });
      //   }
      // });
    }
    this.form = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
    })
  }
  login(): void {

  
    // (window as any).firebase.auth().signInWithEmailAndPassword(this.form.value.username, this.form.value.password)
    // .then(result => {
    //   console.log('result', result)
    //   // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
    // }).catch(error => {
    //   console.log('error', error)
    //   // Handle error.
    // });
  }
}
