import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class Platform {
public browser: boolean = false;
  public mobile: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      console.log('browser');
      this.browser = true;
      if (window && window.innerWidth < 576) {
        this.mobile = true;
      console.log('this.mobile', this.mobile);
      } else {
        this.mobile = false;
      }
    } else {
      console.log('onServer');
      this.browser = false;
    }
  }
}
