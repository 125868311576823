import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import {Location, PathLocationStrategy, ViewportScroller} from '@angular/common';
// import { ProductDetailsComponent } from './product-details/product-details.component';
export const SERVER_IMAGES = 'https://img.exhome.net/';
export const ROUTE_PREFIX_LIST = [
    {
        prefix: 'phong-tro',
        id: '2',
        title: 'Danh sách phòng trọ',
        description: 'Danh sách phòng trọ đẹp, giá rẻ đang được cho thuê, nhượng lại đã đăng gần đây'
    },
    {
        prefix: 'nguoi-can-thue',
        id: '3',
        title: 'Danh sách người cần tìm phòng trọ',
        description: 'Danh sách những người đang cần thuê phòng trọ'
    },
    {
        prefix: 'tim-nguoi-o-ghep',
        id: '6',
        title: 'Danh sách người cần ở ghép',
        description: 'Danh sách những người đang cần ở ghép'
    },
    {
        prefix: 'can-ban-nha',
        id: '10',
        title: 'Danh sách nhà cần bán',
        description: 'Danh sách các bất động sản như đất ở, đất nền, nhà, căn hộ chung cư, căn hộ dịch vụ đang được các chủ nhà và môi giới giao bán'
    },
    {
        prefix: 'nguoi-can-mua-nha',
        id: '11',
        title: 'Danh sách người đang cần mua nhà',
        description: 'Danh sách những người đang cần tìm mua các bất động sản như đất ở, đất nền, nhà, căn hộ chung cư, căn hộ dịch vụ'
    },
]

const _ORIG_PREPAREEXTERNALURL = PathLocationStrategy.prototype.prepareExternalUrl;

PathLocationStrategy.prototype.prepareExternalUrl = function(internal) {
  const url = _ORIG_PREPAREEXTERNALURL.call(this, internal);
  if (url === '') {
    return url;
  }
  if (url === '/chi-tiet/') {
    return url;
  }

  // console.log('For ' + internal + ' we generated ' + url);
  if (url.endsWith('.html')) {
      return url;
  }
  if (url.split('?').length > 1) {
    return url;
  }
  if (url.endsWith('/')) {
    return url;
  }
  return url + '/';
};

Location.stripTrailingSlash = url => {
    const /** @type {?} */ match = url.match(/#|\?|$/);
    const /** @type {?} */ pathEndIdx = match && match.index || url.length;
    const /** @type {?} */ droppedSlashIdx = pathEndIdx - (url[pathEndIdx - 1] === '/' ? 1 : 0);
    const first = url.slice(0, droppedSlashIdx);
    const last = url.slice(pathEndIdx);
    // console.log('stripTrailingSlash', url, first, last);
    if (first.endsWith('.html')) {
        return first + last;
    }
    return first + '/' + last;
    // return first
};

const routes: Routes = [
  // { 
  //   matcher: index,
  //   loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule) 
  // },
  { path: 'so-dien-thoai/', loadChildren: () => import('./so-dien-thoai/so-dien-thoai.module').then(m => m.SoDienThoaiModule) },
  { path: 'so-dien-thoai', loadChildren: () => import('./so-dien-thoai/so-dien-thoai.module').then(m => m.SoDienThoaiModule) },
  {
    path: 'tools.html',
    loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule),
  },
  { path: 'quan-ly/', loadChildren: () => import('./quan-ly/quan-ly.module').then(m => m.QuanLyModule) },
  { path: 'quan-ly', loadChildren: () => import('./quan-ly/quan-ly.module').then(m => m.QuanLyModule) },
  { path: '', loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule) },
  // { path: 'chi-tiet/', component: ProductDetailsComponent },
  // {
  //   matcher: nhaBanIndex,
  //   loadChildren: () => import('./nha-ban-index/nha-ban-index.module').then(m => m.NhaBanIndexModule),
  // },
  // {
  //   matcher: nhaBan,
  //   loadChildren: () => import('./nha-ban/nha-ban.module').then(m => m.NhaBanModule),
  // },
  // {
  //   matcher: hardUrl,
  //   loadChildren: () => import('./nha-dep/nha-dep.module').then(m => m.NhaDepModule),
  // },
  // {
  //   matcher: oto,
  //   loadChildren: () => import('./product-list/danh-sach-by-category/danh-sach-by-category.module').then(m => m.DanhSachByCategoryModule)
  // },
  { path: 'dang-tin-rao.html', loadChildren: () => import('./dang-tin-rao/dang-tin-rao.module').then(m => m.DangTinRaoModule) },
  { path: 'tin-tuc/', loadChildren: () => import('./tin-tuc/tin-tuc.module').then(m => m.TinTucModule) },
  { path: 'tin-tuc', loadChildren: () => import('./tin-tuc/tin-tuc.module').then(m => m.TinTucModule) },
  { path: 'dang-tin-tuc.html', loadChildren: () => import('./dang-tin-tuc/dang-tin-tuc.module').then(m => m.DangTinTucModule) },
  { path: 'chinh-sach-bao-mat.html', loadChildren: () => import('./pages/chinh-sach-bao-mat/chinh-sach-bao-mat.module').then(m => m.ChinhSachBaoMatModule) },
  // {
  //     matcher: phongTro,
  //     // component: DanhSachComponent
  //     loadChildren: () => import('./product-list/danh-sach/danh-sach.module').then(m => m.DanhSachModule),
  // },
  // {
  //   matcher: onlyID,
  //   loadChildren: () => import('./product-list/chi-tiet/chi-tiet.module').then(m => m.ChiTietModule),
  // },
  // {
  //   matcher: idWithType,
  //   loadChildren: () => import('./product-list/chi-tiet-by-category/chi-tiet-by-category.module').then(m => m.ChiTietByCategoryModule),
  // },
  { path: '**', loadChildren: () => import('./classification/classification.module').then(m => m.ClassificationModule) },
  // { path: '**/', loadChildren: () => import('./classification/classification.module').then(m => m.ClassificationModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
    // preloadingStrategy: false,
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
