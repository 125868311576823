<div *ngIf="visible" class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <h2>{{ title }}</h2>
        <button (click)="handleCancel()">X</button>
      </div>
  
      <div class="modal-body">
        <ng-content></ng-content> <!-- Nội dung của modal sẽ được truyền từ ngoài vào -->
      </div>
  
      <div class="modal-footer" *ngIf="footer === null">
        <button (click)="handleCancel()">{{ cancelText }}</button>
        <button (click)="handleOk()">{{ okText }}</button>
      </div>
    </div>
  </div>
  