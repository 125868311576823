export const SERVER_IMAGES = 'https://img.exhome.net/';
export const ROUTE_PREFIX_LIST = [
    {
        prefix: 'phong-tro',
        id: '2',
        title: 'Danh sách phòng trọ',
        description: 'Danh sách phòng trọ đẹp, giá rẻ đang được cho thuê, nhượng lại đã đăng gần đây',
        keyword: 'danh sách phòng trọ, phòng trọ mới nhất'
    },
    {
        prefix: 'nguoi-can-thue',
        id: '3',
        title: 'Danh sách người cần tìm phòng trọ',
        description: 'Danh sách những người đang cần thuê phòng trọ',
        keyword: 'người cần thuê nhà, cần thuê phòng trọ'
    },
    {
        prefix: 'tim-nguoi-o-ghep',
        id: '6',
        title: 'Danh sách người cần ở ghép',
        description: 'Danh sách những người đang cần ở ghép',
        keyword: 'nam ở ghép, nữ ở ghép, tìm người ở ghép'
    },
    {
        prefix: 'can-ban-nha',
        id: '10',
        title: 'Danh sách nhà cần bán',
        description: 'Danh sách các bất động sản như đất ở, đất nền, nhà, căn hộ chung cư, căn hộ dịch vụ đang được các chủ nhà và môi giới giao bán',
        keyword: 'nhà cần bán, cần bán gấp, bán cắt lỗ, nhà mặt đường, nhà trong ngõ, mua nhà'
    },
    {
        prefix: 'nguoi-can-mua-nha',
        id: '11',
        title: 'Danh sách người đang cần mua nhà',
        description: 'Danh sách những người đang cần tìm mua các bất động sản như đất ở, đất nền, nhà, căn hộ chung cư, căn hộ dịch vụ',
        keyword: 'cần mua nhà, mua nhà giá rẻ, mua nhà trong ngõ'
    },
]