import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from './navbar.service';
import { LoginService } from 'src/app/account/login/login.service';
import { AccountService } from  'src/app/account/account.service';
import { AuthService } from  'src/app/service/auth/auth.service';
import { CookieService } from  'src/app/service/cookie.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Platform } from '@/helpers/platform';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    open: boolean = false;
    login: any;
    additionInfo: any;
    constructor(
      private route: ActivatedRoute,
      // public navbar: NavbarService,
      public loginService: LoginService,
      public accountService: AccountService,
      public authService: AuthService,
      public http: HttpClient,
      public platform: Platform
    ) {
      this.login = {
        open: false,
        loading: false,
        loadingGoogle: false,
        error: null,
        username: null,
        password: null,
        submit: () => {
          this.login.loading = true;
          this.http.post<any>(`${environment.api.base}/auth/login`, {
            data: btoa(JSON.stringify({
              username: this.login.username,
              password: this.login.password
            }))
          }).subscribe(res => {
            this.login.loading = false;
            if (res.status === 1) {
              this.login.error = res.alert;
            } else {
              this.login.error = null;
              this.accountService.setLoginStatus(res);
              // localStorage.setItem('accessToken', res.accessToken);
              // localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
              this.login.open = false;
            }
            // console.log('submit', res);
          }, err => {
            this.login.loading = false;
          });
        }
      }
      this.additionInfo = {
        open: false,
        loading: false,
        error: null,
        password: null,
        phone: null
      }
    }

    ngOnInit() {
      if (this.platform.browser) {
        // TODO: Replace the following with your app's Firebase project configuration
        // var firebaseConfig = {
        //   apiKey: "AIzaSyA1LP0GPlsVAivr08pOnson5aQjod2Ykmo",
        //   authDomain: "exhome-auth.firebaseapp.com",
        //   projectId: "exhome-auth",
        //   storageBucket: "exhome-auth.appspot.com",
        //   messagingSenderId: "465791454203",
        //   appId: "1:465791454203:web:7b510652224fbc752dd95e",
        //   measurementId: "G-JCPN4WDKWP"
        // };

        // // Initialize Firebase
        // (window as any).firebase.initializeApp(firebaseConfig);




        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-158950909-1';
        document.head.appendChild(script);
    
        const inlineScript = document.createElement('script');
        inlineScript.text = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-158950909-1');
        `;
        document.head.appendChild(inlineScript);

      }

      
    }

    loginFacebook(): void {
      const provider = new (window as any).firebase.auth.FacebookAuthProvider();
      provider.addScope('user_birthday');

      // const auth = getAuth();
      (window as any).firebase.auth().signInWithPopup(provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        console.log('result', result);

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const credential = FacebookAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
        (window as any).firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
          // Send token to your backend via HTTPS
          console.log('idToken', idToken);
          this.http.post<any>(`${environment.api.base}/auth/checkExist`, {idToken}).subscribe(res => {
            console.log('checkExist', res);
            // this.login.loadingGoogle = false;
            if (res.status === 1) {
              this.login.error = res.alert;
            } else {
              this.login.error = null;
              this.accountService.setLoginStatus(res);
              this.login.open = false;
            }
          });
        });
      })
      .catch((error) => {
        console.log('error', error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
    }

    loginGoogle(): void {
      this.login.loadingGoogle = true;
      var provider = new (window as any).firebase.auth.GoogleAuthProvider();
      
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      // (window as any).firebase.auth().signInWithRedirect(provider);
      // return;

      (window as any).firebase.auth().languageCode = 'en';

      (window as any).firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        var idToken = credential.idToken;
        var token2 = (window as any).firebase.auth.currentUser;
        // The signed-in user info.
        var user = result.user;
        // IdP data available in result.additionalUserInfo.profile.
          // ...
        // console.log(credential, idToken, token2);
        (window as any).firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
          // Send token to your backend via HTTPS
          console.log('idToken', idToken);
          this.http.post<any>(`${environment.api.base}/auth/checkExist`, {idToken}).subscribe(res => {
            console.log('checkExist', res);
            // if ((res.status === 0) && (res.code === 'USER_NOT_EXIST')) {

            // } else if ((res.status === 0) && (res.code === 'USER_EXIST')) {

            // }
            this.login.loadingGoogle = false;
            if (res.status === 1) {
              this.login.error = res.alert;
            } else {
              this.login.error = null;
              this.accountService.setLoginStatus(res);
              // localStorage.setItem('accessToken', res.accessToken);
              // localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
              this.login.open = false;
            }
          });
          // ...
        }).catch(function(error) {
          // Handle error
        });
      }).catch((error) => {
        console.log(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });


      
    }


}