export const environment = {
  production: true,
  api: {
    base: 'https://api.exhome.net',
    getList: 'https://api.exhome.net/get_list',
    getDetail: 'https://api.exhome.net/get_detail',
    getPlaceList: 'https://api.exhome.net/get_place_list',
    getAddress: 'https://api.exhome.net/get_address',
    getThreadNhaDep: 'https://api.exhome.net/get_thread_nha_dep',
    getThreadKinhTeXaHoi: 'https://api.exhome.net/get_thread_kinh_te_xa_hoi',
    getListTraning: 'https://api.exhome.net/get_list_tagging',
    updateListTraning: 'https://api.exhome.net/update_list_tagging',
    getDetailByCategory: 'https://api.exhome.net/getDetailByCategory',
    getListByCategory: 'https://api.exhome.net/getListByCategory',
    staticGetTopPost: 'https://api.exhome.net/staticGetTopPost',
    staticGetTopCategory: 'https://api.exhome.net/staticGetTopCategory',
    listPosts: 'https://api.exhome.net/listPosts',
    // Manage
    getCleanMessages: 'https://api.exhome.net/manage/getCleanMessages',
    updateCleanMesssage: 'https://api.exhome.net/manage/updateCleanMesssage',
    getListLabel: 'https://api.exhome.net/manage/getListLabel',
    getLabelClassification: 'https://api.exhome.net/manage/getLabelClassification',
    updateClassification: 'https://api.exhome.net/manage/updateClassification',
    traningModelClassification: 'https://api.exhome.net/manage/traningModelClassification',
    getThreads: 'https://api.exhome.net/manage/getThreads',
    createCleanMesssage: 'https://api.exhome.net/manage/createCleanMesssage',
    traningModel: 'https://api.exhome.net/manage/traningModel',
    deleteDataTraning: 'https://api.exhome.net/manage/deleteDataTraning',
    getListCampaign: 'https://api.exhome.net/manage/getListCampaign',
    getListCrowd: 'https://api.exhome.net/manage/getListCrowd',
    createLabel: 'https://api.exhome.net/manage/createLabel',
    get_list_predicted: 'https://api.exhome.net/manage/get_list_predicted',
    // V2
    v2_getThreadDetail: 'https://api.exhome.net/v2/get_detail',
    v2_getThreadList: 'https://api.exhome.net/v2/get_list_thread'
  }
};
