import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Platform } from './platform';

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptService {
  public listScript = {};
  constructor(
    public platform: Platform
  ) { }

  public load(src): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        if (!this.listScript.hasOwnProperty(src)) {
          this.listScript[src] = new BehaviorSubject(null);
          if (this.platform.browser) {
            const scriptElement = window.document.createElement('script');
            scriptElement.src = src;
            window.document.body.appendChild(scriptElement);
            scriptElement.onload = () => {
              for (const [key, value] of Object.entries(this.listScript)) {
                if (key === src) {
                  // console.log('', src);
                  this.listScript[src].next(true);
                }
              }
              resolve();
            };
            scriptElement.onerror = () => {
              reject();
            };
          } else {
            reject();
          }

        } else {
          resolve();
        }
    });
  }
}
