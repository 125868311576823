import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TimeService } from '../helpers/time.service';
import { AccountService } from '@/account/account.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    public notification: NzNotificationService,
    public timeService: TimeService,
    public accountService: AccountService
  ) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const start = new Date().getTime();
    return next.handle(request)
    .pipe(
      tap(
        (event: any) => {
          // console.log('event', event);
          if (event?.body?.time) {
            const currentTime = new Date().getTime();
            this.timeService.currentTime = currentTime;
            this.timeService.serverTime = event.body.time;
            this.timeService.internetTime = currentTime - start;
          }
          if (event && event.body && (event.body.status === 401)) {
            this.accountService.logout();
          }
          if (event && event.body && (event.body.status === 1)) {
            this.notification.create(
                'error',
                'Error',
                `${event.body.description}`
            );
          }
        },
        (err: any) => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
                // this.accountService.logout();
            } else {
              this.notification.create(
                'error',
                `Error [${err.status}]`,
                `${err.message}`
              );
            }
          }
        }
      )
    );
  }
}
