<div class="d-flex flex-column flex-md-row align-items-center px-md-4 bg-white" style="box-shadow: 0px 0px 20px rgba(0,0,0,.02);">
    <div class="container mx-auto">
        <div class="flex sm:hidden justify-between px-2 px-sm-0 py-2 py-sm-3">
            <div class="d-flex">
                <div class="mr-1">
                    <a title="Tìm phòng trọ và người ở ghép nhanh nhất Hà Nội" class="logo" [routerLink]="['/']">
                        <img alt="Exhome logo - Tìm phòng trọ và người ở ghép nhanh nhất Hà Nội" src="assets/images/logo-small.png" style="border-radius: 4px;" />
                    </a>
                </div>
                <app-modal-drawer
                    [closable]="true"
                    [(visible)]="open"
                    placement="left" 
                    [title]="extra"
                    [bodyStyle]="{padding: '0px'}"
                    (onClose)="open = false">
                    <ul class="p-0 m-0 w-full flex flex-col">
                        <li class="">
                            <a class="flex p-3 bg-[#f6faf6] text-[13px] text-[green] font-[700]">Nhà Bán</a>
                            <ul class="">
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="ban-dat" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Đất đang rao bán</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="ban-nha-dat" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Nhà đất đang rao bán</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="ban-can-ho-chung-cu" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Chung cư đang rao bán</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="bat-dong-san-dong-tien" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Bất động sản dòng tiền đang rao bán</a>
                                </li>
                            </ul>
                        </li>
                        <li class="">
                            <a class="flex p-3 bg-[#f6faf6] text-[13px] text-[green] font-[700]">Nhà Thuê</a>
                            <ul class="">
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="cho-thue-phong-tro" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Phòng trọ cho thuê</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="cho-thue-can-ho-chung-cu" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Căn hộ chung cư cho thuê</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="cho-thue-mat-bang-kinh-doanh" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Mặt bằng kinh doanh cho thuê</a>
                                </li>
                                <li class="m-0 p-0">
                                    <a (click)="open = false" routerLink="cho-thue-nha-nguyen-can" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Nhà nguyên căn cho thuê</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </app-modal-drawer>
                <ng-template #extra>
                    <img alt="Exhome logo - Tìm phòng trọ và người ở ghép nhanh nhất Hà Nội" src="assets/images/logo.png" />
                </ng-template>
            </div>
            <div (click)="open = true">
                <svg style="width: 24px; height: 24px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-bars fa-w-14 fa-2x"><path fill="currentColor" d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z" class=""></path></svg>
            </div>
        </div>
        <div class="hidden sm:flex flex-col sm:flex-row items-center justify-between">
            <h5 class="my-0 mr-md-auto font-weight-normal">
                <a title="Tìm phòng trọ và người ở ghép nhanh nhất Hà Nội" class="logo" [routerLink]="['/']">
                    <img alt="Exhome logo - Tìm phòng trọ và người ở ghép nhanh nhất Hà Nội" src="assets/images/logo.png" />
                </a>
            </h5>
            <nav class="flex flex-row justify-end items-center">
                <ul class="p-0 m-0 flex flex-row items-center">
                    <li class="group relative ml-3">
                        <a class="flex py-5 text-[16px] border-y-[4px] border-y-[transparent] hover:border-b-[green] hover:text-[green!important]">Nhà Bán</a>
                        <ul class="m-0 hidden group-hover:flex flex-col bg-[#fff] border-t-[#e7e7e7!important] px-[0px] py-[10px] w-[200px] absolute z-50 bot-0 left-0 drop-shadow-2xl">
                            <li class="m-0 p-0">
                                <a routerLink="ban-dat" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Đất đang rao bán</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="ban-nha-dat" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Nhà đất đang rao bán</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="ban-can-ho-chung-cu" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Chung cư đang rao bán</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="bat-dong-san-dong-tien" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Bất động sản dòng tiền đang rao bán</a>
                            </li>
                        </ul>
                    </li>
                    <li class="group relative ml-3">
                        <a class="flex py-5 text-[16px] border-y-[4px] border-y-[transparent] hover:border-b-[green] hover:text-[green!important]">Nhà Thuê</a>
                        <ul class="m-0 hidden group-hover:flex flex-col bg-[#fff] border-t-[#e7e7e7!important] px-[0px] py-[10px] w-[200px] absolute z-50 bot-0 left-0 drop-shadow-xl">
                            <li class="m-0 p-0">
                                <a routerLink="cho-thue-phong-tro" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Phòng trọ cho thuê</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="cho-thue-can-ho-chung-cu" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Căn hộ chung cư cho thuê</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="cho-thue-mat-bang-kinh-doanh" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Mặt bằng kinh doanh cho thuê</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="cho-thue-nha-nguyen-can" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Nhà nguyên căn cho thuê</a>
                            </li>
                        </ul>
                    </li>
                    <li class="group relative ml-3">
                        <a href="/tin-tuc/" class="flex py-5 text-[16px] border-y-[4px] border-y-[transparent] hover:border-b-[green] hover:text-[green!important]">Tin tức</a>
                    </li>
                    <li class="ml-3" *ngIf="!accountService.accessToken">
                        <!-- <button (click)="additionInfo.open = true">Open</button> -->
                        <!-- <button class="btn btn-primary" (click)="accountService.popupLoginOpen = true">Đăng nhập</button> -->
                        <button nz-button [nzType]="'primary'" (click)="login.open = true">Đăng nhập</button>
                    </li>
                    <li class="group relative ml-3" *ngIf="accountService.accessToken">
                        <a class="flex py-5 text-[16px] border-y-[4px] border-y-[transparent] hover:border-b-[green] hover:text-[green!important]">Xin chào, {{accountService.userInfo.name}}</a>
                        <ul class="m-0 hidden group-hover:flex flex-col bg-[#fff] border-t-[#e7e7e7!important] px-[0px] py-[10px] w-[200px] absolute z-50 bot-0 left-0 drop-shadow-xl">
                            <li class="m-0 p-0">
                                <a routerLink="./dang-tin-rao.html" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Đăng tin rao</a>
                            </li>
                            <li class="m-0 p-0">
                                <a routerLink="./dang-tin-tuc.html" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Đăng tin tức</a>
                            </li>
                            <li class="m-0 p-0">
                                <a (click)="accountService.logout()" class="flex px-[15px] py-[12px] text-[#333] hover:bg-[#f1f1f1]">Đăng xuất</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                
            </nav>
        </div>
        

    </div>
    
</div>
<div>

</div>

<!-- <app-popup [(open)]="accountService.popupLoginOpen">
    <ng-template app-popup-header>Đăng nhập</ng-template>
    <ng-template app-popup-body>
        <app-login></app-login>
    </ng-template>
</app-popup>  -->


<app-modal-popup 
    [(visible)]="login.open" 
    title="Đăng nhập"
    [footer]="null"
    okText="Đăng nhập"
    cancelText="Hủy"
    (onCancel)="login.open = false" 
    (onOk)="login.open = false">

        
        <div class="flex flex-col gap-4">
            <div class="text-[18px] font-[500]">Vui lòng đăng nhập để tiếp tục sử dụng</div>
            <!-- <nz-alert
            *ngIf="login.error"
            nzType="error"
            nzCloseable
            [nzMessage]="login.error"
            ></nz-alert>
            <nz-input-group nzPrefixIcon="lock">
                <input type="text" nz-input nzSize="large" [disabled]="login.loading" (keyup.enter)="login.submit()" [(ngModel)]="login.username" placeholder="Username" />
            </nz-input-group>
            <nz-input-group nzPrefixIcon="lock">
                <input type="password" nz-input nzSize="large" [disabled]="login.loading" (keyup.enter)="login.submit()" [(ngModel)]="login.password" placeholder="Password" />
            </nz-input-group>
            <button nz-button nzType="primary" nzBlock [nzLoading]="login.loading" (click)="login.submit()">Đăng nhập</button>
            <hr />
            <button nz-button nzType="default" (click)="loginGoogle()" [disabled]="login.loadingGoogle">
                <div class="flex flex-row justify-center items-center gap-1">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M7.54594 13.6689L6.989 15.7481L4.95341 15.7911C4.34506 14.6628 4 13.3718 4 11.9999C4 10.6733 4.32263 9.42233 4.8945 8.3208H4.89494L6.70719 8.65305L7.50106 10.4544C7.33491 10.9388 7.24434 11.4588 7.24434 11.9999C7.24441 12.5872 7.35078 13.1498 7.54594 13.6689Z" fill="#FBBB00"></path><path d="M19.8601 10.5054C19.952 10.9893 19.9999 11.4891 19.9999 11.9999C19.9999 12.5726 19.9397 13.1313 19.825 13.6702C19.4355 15.5041 18.4179 17.1054 17.0083 18.2386L17.0078 18.2381L14.7252 18.1217L14.4022 16.1049C15.3375 15.5564 16.0685 14.6979 16.4536 13.6702H12.1758V10.5054H16.516H19.8601Z" fill="#518EF8"></path><path d="M17.0081 18.2382L17.0086 18.2386C15.6376 19.3406 13.896 19.9999 12.0002 19.9999C8.95361 19.9999 6.30483 18.2971 4.95361 15.7911L7.54614 13.6689C8.22174 15.472 9.96108 16.7555 12.0002 16.7555C12.8767 16.7555 13.6978 16.5186 14.4024 16.105L17.0081 18.2382Z" fill="#28B446"></path><path d="M17.1064 5.84175L14.5147 7.9635C13.7855 7.50769 12.9235 7.24437 12 7.24437C9.91475 7.24437 8.14287 8.58678 7.50112 10.4545L4.89497 8.32087H4.89453C6.22597 5.75384 8.90816 4 12 4C13.9411 4 15.7209 4.69144 17.1064 5.84175Z" fill="#F14336"></path></svg>
                    <span class="font-[500]">Đăng nhập với Google</span>
                </div>
            </button>
            <button nz-button nzType="default" (click)="loginFacebook()">
                <div class="flex flex-row justify-center items-center gap-1">
                    <span class="font-[500]">Đăng nhập với Facebook</span>
                </div>
            </button> -->
        </div>
</app-modal-popup>

<!-- <nz-modal 
    [(nzVisible)]="additionInfo.open" 
    nzTitle="Đăng nhập"
    [nzFooter]="null"
    nzOkText="Đăng nhập"
    nzCancelText="Hủy"
    (nzOnCancel)="additionInfo.open = false" 
    (nzOnOk)="additionInfo.open = false">
    <ng-container *nzModalContent>
        <div>Đặt mật khẩu để đăng nhập</div>
        <div class="flex flex-col gap-4">
            <nz-input-group nzPrefixIcon="lock">
                <input type="text" nz-input nzSize="large"  [(ngModel)]="additionInfo.password" placeholder="Mật khẩu" />
            </nz-input-group>
            <nz-input-group nzPrefixIcon="lock">
                <input type="text" nz-input nzSize="large"  [(ngModel)]="additionInfo.phone" placeholder="Điện thoại" />
            </nz-input-group>
            <button nz-button nzType="primary" nzBlock [nzLoading]="additionInfo.loading" (click)="login.submit()">Tiếp tục</button>
        </div>
    </ng-container>
</nz-modal> -->


<div class="container mx-auto">
    <div class="unset lg:flex overflow-x-auto justify-center">
        <div class="adv p-2 unset lg:flex gap-2 justify-center w-[1000px]">
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/RZ9Hpxj6">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/818a3ce04f6b195ba6ce7ed21c4092cb" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/7prytchJ">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/062e2050e6581d9aa6ca93ee0881fb5e" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/zyhGpQja">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/0ded1272a668e592e4e9f7b6be7d2489" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/2C6YBn1W">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/dabec10fa9d912dd25f921b929400683" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/CsgZBxrN">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/2351c36c9471cc25e9cbc5b9d3b518a0" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/3fBjqQRc">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/f68e908883ca4c26b40b542a25722e7b" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/k4FVnvFk">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/b46704dd9ccc08452f4ec5d2954bdc10" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/WgA6cYwX">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/767cc64162558187b3a2b6ff0d166925" />
            </a>
            <a rel="no-follow" class="inline-block" href="https://shorten.asia/fqcqnTpg">
                <img class="w-[100px] h-[100px]" src="https://cf.shopee.vn/file/e658ee1cbf64185ceb20de98035259cb" />
            </a>
        </div>
    </div>
</div>

